import { Component } from "react";

export default class HomeComponent extends Component{
    constructor(props){
        super(props)
    }

    render(){
        return <h4>Home</h4>
    }
}